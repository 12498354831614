import React, { useState } from "react";
import config from "../../../config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menuItems } from "../../../menu-items";
import { IconBrandHipchat } from "@tabler/icons-react";

//Material UI
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  ClickAwayListener,
  useTheme,
  Badge,
} from "@mui/material";

import { Menu as MenuIcon, Logout } from "@mui/icons-material";
import NavLogo from "./NavLogo";
import { useDispatch, useSelector } from "react-redux";
import { resetAppSlice } from "../../../redux/app/appSlice";
import { privateApiPOST, wrapBaseURL } from "../../../components/PrivateRoute";
import Api from "../../../components/Api";

import { IconSearch, IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { resetQuerySlice } from "../../../views/MicroBot/reduxComp/query/querySlice";

const Navbar = () => {
  const { isSidebarOpened, profileData, isBotSettingLoading } = useSelector(
    (state) => state.app
  );
  const { user_basic_info } = profileData;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // User Logout
  const handleUserLogout = () => {
    privateApiPOST(wrapBaseURL(Api.logout))
      .then((res) => {
        console.log("Logout API response ---> ", res);
        localStorage.removeItem("token");
        dispatch(resetQuerySlice());
        dispatch(resetAppSlice());
        // localStorage.removeItem("botSettings");
        // dispatch(setBotSettingsData({}));
        // dispatch(setBotSettingsLoading(!isBotSettingLoading));
        navigate("/login");
      })
      .catch((error) => {
        localStorage.removeItem("token");
        dispatch(resetQuerySlice());
        dispatch(resetAppSlice());
        // localStorage.removeItem("botSettings");
        // dispatch(setBotSettingsData({}));
        // dispatch(setBotSettingsLoading(!isBotSettingLoading));
        navigate("/login");
      });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => dispatch(setSiderbarOpened(!isSidebarOpened))}
        >
          <MenuIcon />
        </IconButton> */}

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="Search"
        >
          <IconSearch size={35} stroke={1.5} />
        </IconButton>
        <NavLogo />
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <IconAdjustmentsHorizontal size={35} stroke={1.5} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "160px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem disableTouchRipple>Hi, {user_basic_info?.name}</MenuItem>
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem> */}
            <MenuItem onClick={handleUserLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
